<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-material-card
      color="light-blue"
      icon="mdi-account-tie"
      title="Ofertas"
      class="px-5 py-3"
    >
      <v-data-table
        v-if="ofertaslist"
        :headers="headers"
        :items="ofertas"
        :search="search"
        :loading="loading"
        class="elevation-1 mb-10"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            />
            <v-spacer />
            <v-btn
              color="primary"
              fab
              dark
              small
              class="mb-2 mr-3"
              @click="addOfertas()"
            >
              <v-icon
                dark
              >
                mdi-plus
              </v-icon>
            </v-btn>
            <v-btn
              color="red"
              fab
              dark
              small
              class="mb-2"
              @click="reportModalTrigger()"
            >
              <v-icon dark>
                mdi-file-chart
              </v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr
            v-if="item.estado == 'PENDIENTE'"
            class="pendient"
          >
            <td>{{ item.fecha }}</td>
            <td>{{ item.ofertante_name }}</td>
            <td>{{ item.oferta | recorte }}</td>
            <td>{{ item.condiciones }}</td>
            <td>{{ item.candidata }}</td>
            <td>{{ item.estado }}</td>
            <td>
              <v-icon
                small
                class="mr-2"
                color="success"
                @click="editItem(item)"
              >
                mdi-pencil-outline
              </v-icon>
              <v-icon
                small
                color="error"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </td>
          </tr>
          <tr
            v-else-if="item.estado == 'CURSO'"
            class="oncourse"
          >
            <td>{{ item.fecha }}</td>
            <td>{{ item.ofertante_name }}</td>
            <td>{{ item.oferta | recorte }}</td>
            <td>{{ item.condiciones }}</td>
            <td>{{ item.candidata }}</td>
            <td>{{ item.estado }}</td>
            <td>
              <v-icon
                small
                class="mr-2"
                color="success"
                @click="editItem(item)"
              >
                mdi-pencil-outline
              </v-icon>
              <v-icon
                small
                color="error"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </td>
          </tr>
          <tr
            v-else
            class="finalize"
          >
            <td>{{ item.fecha }}</td>
            <td>{{ item.ofertante_name }}</td>
            <td>{{ item.oferta | recorte }}</td>
            <td>{{ item.condiciones }}</td>
            <td>{{ item.candidata }}</td>
            <td>{{ item.estado }}</td>
            <td>
              <v-icon
                small
                class="mr-2"
                color="success"
                @click="editItem(item)"
              >
                mdi-pencil-outline
              </v-icon>
              <v-icon
                small
                color="error"
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
      <addOfertas
        :edited-item="datos"
        :edited-index="editedIndex"
      />
      <modalReportOfertas
        :show-dialog="showModalReport"
        @close-action="reportModalTrigger"
      />
    </base-material-card>
  </v-container>
</template>

<script>
  import Vue from 'vue'
  import addOfertas from './addOfertas.vue'
  import modalReportOfertas from './modalReportOfertas.vue'
  export default {
    name: 'OfertasTable',
    components: {
      addOfertas,
      modalReportOfertas,
    },
    data: vm => {
      return {
        search: '',
        snackbar: false,
        colorSnak: 'info',
        text: '',
        timeout: 3000,
        datos: {},
        editedIndex: -1,
        headers: [
          {
            text: 'Fecha',
            align: 'left',
            value: 'fecha',
          },
          {
            text: 'Ofertante',
            align: 'left',
            value: 'ofertante_name',
          },
          {
            text: 'Oferta',
            align: 'left',
            sortable: false,
            filterable: false,
            value: 'oferta',
          },
          {
            text: 'Condiciones',
            align: 'left',
            sortable: false,
            filterable: false,
            value: 'condiciones',
          },
          {
            text: 'Candidata',
            align: 'left',
            value: 'candidata',
          },
          {
            text: 'Estado',
            align: 'left',
            value: 'estado',
          },
          {
            text: 'Acciones',
            value: 'acciones',
            sortable: false,
            filterable: false,
          },
        ],
        emailRules: [
          v => !!v || 'EL correo electrónico es requerido',
          v => /.+@.+\..+/.test(v) || 'Ingresa un correo valido',
        ],
        showModalReport: false,
      }
    },
    computed: {
      ofertaslist: {
        get () {
          return this.$store.state.ofertas.ofertaslist
        },
      },
      ofertas: {
        get () {
          return this.$store.state.ofertas.ofertas
        },
        set (value) {
          return this.$store.commit('ofertas/setOfertas', value)
        },
      },
      loading: {
        get () {
          return this.$store.state.ofertas.loading
        },
      },
    },
    mounted () {
      this.$store.dispatch('ofertas/getOfertas')
      this.$store.dispatch('ofertantes/getOfertantes')
    },
    methods: {
      addOfertas () {
        this.datos = {}
        this.editedIndex = -1
        this.$store.commit('ofertas/setRegister', true)
      },
      editItem (item) {
        this.editedIndex = this.ofertas.indexOf(item)
        this.datos = Object.assign({}, item)
        this.$store.commit('ofertas/setRegister', true)
      },
      deleteItem (item) {
        this.$confirm('Está seguro que desea eliminar el registro?', {
          buttonTrueText: 'Si',
          buttonFalseText: 'No',
        })
          .then(res => {
            if (res) {
              this.$store.dispatch('ofertas/deleteOfertas', item.id)
                .then((response) => {
                  Vue.notify({
                    group: 'loggedIn',
                    text: response.data.success,
                    type: 'teal accent-3',
                    duration: 5000,
                  })
                })
                .catch(err => {
                  // eslint-disable-next-line
                  console.log(err)
                  Vue.notify({
                    group: 'loggedIn',
                    text: 'No se pudo eliminar en este momento',
                    type: 'red accent-2',
                    duration: 5000,
                  })
                },
                )
            }
          })
      },
      reportModalTrigger () {
        this.showModalReport = !this.showModalReport
      },
    },

  }
</script>

<style>
  .v-card {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .pendient {
    color: #ff2f00;
  }
  .oncourse{
    color: #0080ff;
  }
  .finalize{
    color: #005e49;
  }
</style>
