<template>
  <v-card
    v-if="ofertasadd"
    class="mb-10"
  >
    <template>
      <!-- <v-subheader>Datos Personales</v-subheader> -->
      <v-container fluid>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <input
            v-model="editedItem.id"
            type="hidden"
          >
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="4"
            >
              <v-menu
                ref="menuFn"
                v-model="menuFn"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <label>Fecha de Registro OFERTAS</label>
                  <v-text-field
                    v-model="editedItem.fecha"
                    label="Fecha de Registros"
                    hint="DD/MM/YYYY"
                    solo
                    persistent-hint
                    prepend-icon="mdi-calendar-month"
                    v-bind="attrs"
                    :rules="[v => !!v || 'Fecha es requerida']"
                    @blur="dateFn = parseDate(editedItem.fecha)"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="dateFn"
                  locale="es"
                  show-current="false"
                  @input="menuFn = false"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="4"
            >
              <label>Candidata</label>
              <v-text-field
                v-model="editedItem.candidata"
                solo
                label="Candidata"
                :rules="[v => !!v || 'Campo requerido']"
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="4"
            >
              <label>Estado</label>
              <v-select
                v-model="editedItem.estado"
                label="Estado"
                :items="['PENDIENTE', 'EN CURSO', 'FINALIZADA']"
                solo
                :rules="[v => !!v || 'Campo requerido']"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <label>Oferta</label>
              <v-textarea
                v-model="editedItem.oferta"
                name="input-7-1"
                label="Oferta"
                solo
                :rules="[v => !!v || 'Campo requerido']"
                auto-grow
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <label>Condiciones</label>
              <v-textarea
                v-model="editedItem.condiciones"
                name="input-7-1"
                label="Condiciones"
                solo
                :rules="[v => !!v || 'Campo requerido']"
                auto-grow
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <label>Ofertante</label>
              <v-overflow-btn
                v-model="editedItem.ofertante"
                label="Ofertante"
                attach
                solo
                editable
                :items="
                  ofertantes.map(function(item) {
                    return {
                      text: item.nombre_apellido,
                      value: item.id
                    };
                  })
                "
                :menu-props="{maxHeight:200 }"
                clearable
                required
                :rules="[v => !!v || 'Campo requerido']"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <label>Donativo</label>
              <v-text-field
                v-model="editedItem.donativo"
                solo
                label="Donativo"
                suffix="€"
                :rules="donativoRule"
                hint="Si va a agregar decimales, deben ser siempre dos. Formato de ejemplo: 1.345,30"
                persistent-hint
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <label>Observaciones</label>
              <v-text-field
                v-model="editedItem.observaciones"
                solo
                label="Observaciones"
              />
            </v-col>
          </v-row>
          <v-card-actions>
            <v-row>
              <v-spacer />
              <v-btn
                color="#F6F6F6"
                style="color:black;"
                @click="volver"
              >
                {{ $t("close") }}
              </v-btn>
              <v-btn
                class="mr-4"
                color="primary"
                :loading="loading"
                @click="submit"
              >
                {{ $t("save") }}
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-form>
      </v-container>
    </template>
  </v-card>
</template>

<script>
  import Vue from 'vue'
  export default {
    name: 'AddOfertas',
    props: {
      editedItem: {
        type: Object,
        required: true,
      },
      editedIndex: {
        type: Number,
        default: 0,
      },
    },
    data: vm => {
      return {
        tab: null,
        valid: true,
        dateFn: new Date().toISOString().substr(0, 10),
        menuFn: false,
        search: '',
        reset: false,
        emailRules: [
          v => !!v || 'EL correo electrónico es requerido',
          v => /.+@.+\..+/.test(v) || 'Ingresa un correo valido',
        ],
        edad: [
          v => {
            if (!isNaN(parseFloat(v)) && v >= 0) return true
            return 'Ingresa un número'
          },
        ],
        donativoRule: [
          // No es obligatorio, pero si se ingresa un valor se debe cumplir la regla
          (v) => (!v || /^(?:(?:\d{1,3}(\.\d{3})*(,\d{2})?)|\d+,\d{2})$/.test(v)) || 'Si va a agregar decimales, deben ser siempre dos. Formato de ejemplo: 1.345,30',
        ],
        editedItem: {
          id: 0,
          fecha: '',
          oferta: '',
          condiciones: '',
          candidata: '',
          ofertante: '',
          donativo: '',
          observaciones: '',
          estado: 'PENDIENTE',
        },
      }
    },
    computed: {
      ofertasadd: {
        get () {
          return this.$store.state.ofertas.ofertasadd
        },
      },
      ofertantes: {
        get () {
          return this.$store.state.ofertantes.ofertantes
        },
      },

      loading: {
        get () {
          return this.$store.state.ofertas.loading
        },
      },
    },
    watch: {
      dateFn (val) {
        this.editedItem.fecha = this.formatDate(this.dateFn)
      },
      editedItem (val) {
        if (val.donativo > 0) {
          const donativo = val.donativo
          const formattedNumber = donativo.toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          val.donativo = formattedNumber
        }
      },
    },
    async beforeMount () {
      await this.$store.commit('ofertas/setRegister', false)
    },
    methods: {
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null
        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      volver () {
        this.editedIndex = -1
        this.$refs.form.reset()
        this.$store.commit('ofertas/setRegister', false)
      },
      submit () {
        if (this.$refs.form.validate()) {
          const data = {
            index: this.editedIndex,
            id: this.editedItem.id,
            fecha: this.editedItem.fecha,
            oferta: this.editedItem.oferta,
            condiciones: this.editedItem.condiciones,
            candidata: this.editedItem.candidata,
            estado: this.editedItem.estado,
            ofertante: this.editedItem.ofertante,
            donativo: this.editedItem.donativo,
            observaciones: this.editedItem.observaciones,
          }

          if (this.editedIndex < 0) {
            this.$store.dispatch('ofertas/addOfertas', data)
              .then((response) => {
                Vue.notify({
                  group: 'loggedIn',
                  text: response.data.success,
                  type: 'teal accent-3',
                  duration: 5000,
                })
              })
              .catch(err => {
                // eslint-disable-next-line
                console.log(err)
                Vue.notify({
                  group: 'loggedIn',
                  text: 'No se pudo guardar en este momento',
                  type: 'red accent-2',
                  duration: 5000,
                })
              },
              )
          } else {
            this.$store.dispatch('ofertas/updateOfertas', data)
              .then((response) => {
                Vue.notify({
                  group: 'loggedIn',
                  text: response.data.success,
                  type: 'teal accent-3',
                  duration: 5000,
                })
                if (response.data.email) {
                  setTimeout(() => {
                    this.$store.dispatch('logout')
                  }, 3000)
                }
              })
              .catch(err => {
                // eslint-disable-next-line
                console.log(err)
                Vue.notify({
                  group: 'loggedIn',
                  text: 'No se pudo guardar en este momento',
                  type: 'red accent-2',
                  duration: 5000,
                })
              },
              )
          }
        }
      },
    },
  }
</script>

<style scoped>
.v-card {
  margin-top: 0px;
  margin-bottom: 0px;
}
.v-card__title {
  background-color: aliceblue;
}
.expansionPanel {
  width: 100%;
}
.vue-tel-input {
  height: 50px;
  border: none;
  border-bottom: 1px solid #bbb;
}
</style>
